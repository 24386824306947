<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/temp/banner_img003.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}/company?t=${index}`">{{item.title}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">{{hdTitle}}</div>
              <div class="fr listHdGrop"><a href="/">首页</a> · <a :href="`${apiName}/company`">{{slideHd.title}}</a> · <span class="txt">{{hdTitle}}</span> </div>
            </div>
            <div class="listContainer">
              <detail-page ref="detailPage" :gropId="gropId" v-if="pageType == 1"></detail-page>
              <list-page ref="listPage" :gropId="gropId" v-if="pageType == 2"></list-page>
              <list-img ref="listImg" :gropId="gropId" v-if="pageType == 3"></list-img>
              <list-video ref="listVideo" :gropId="gropId" v-if="pageType == 4"></list-video>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import listPage from '@/components/list.vue'
import detailPage from '@/components/detail.vue'
import listImg from '@/components/listImg.vue'
import listVideo from '@/components/listVideo.vue'
export default {
  components: {
    listPage,
    detailPage,
    listImg,
    listVideo
  },
  name: 'list',
  data(){
    return {
      pageType: 0,
      gropId: '',
      hdTitle: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '公司概况',
        sub: 'COMPANY',
      },
      // type 1: 文章；2：列表；3：图片列表；4：视频列表；
      slideList:[
        {
          title: '公司简介',
          id: '341',
          type: 1
        },
        {
          title: '领导成员',
          id: '531',
          type: 1
        },
        {
          title: '组织架构',
          id: '342',
          type: 1
        },
        {
          title: '子公司简介',
          id: '343',
          type: 1
        },
        {
          title: '企业文化',
          id: '344',
          type: 1
        },
        {
          title: '公司荣誉',
          id: '345',
          type: 1
        }
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
      ],
      page: [
        '<p>公司简介</p><p>公司简介</p><p>公司简介</p> <p>公司简介</p><p>公司简介</p>',
        '<p>管理团队</p><p>管理团队</p><p>管理团队</p> <p>管理团队</p><p>管理团队</p>',
        '<p>企业文化</p><p>企业文化</p><p>企业文化</p> <p>企业文化</p><p>企业文化</p>',
      ]
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    this.$nextTick(() => {
      _this.gropId = _this.slideList[_this.active].id
      _this.pageType = _this.slideList[_this.active].type
    })
    _this.hdTitle = _this.slideList[_this.active].title
    _this.pageCont = _this.page[_this.active]
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    }
  }
}
</script>